/************/
/* Elements */
/************/
body
{
	max-width	: 1000px;
	/* syntaxtic for opera may remove margins if we don't use !important */
	margin		: 5px auto !important;
	
	background:
		url(images/cloud1.svg) no-repeat 20% 10vmin / 40vmin,
		url(images/cloud2.svg) no-repeat 70% 24vmin / 24vmin,
		url(images/nilagain.png) no-repeat 5% 300px / 28vmin,
		url(images/nilagain2.png) no-repeat 95% 80% / 17vmin,
		url(images/nilagain3.png) no-repeat 5% 95% / 15vmin,
		url(images/nilagain4.png) no-repeat 1% 40% / 25vmin,
		url(images/nilagain5.png) no-repeat 99% 22% / 23vmin,
		url(images/nilagain6.png) no-repeat 95% 96% / 21vmin,
		url(images/stars.svg) repeat top left / 30rem,
		radial-gradient(circle closest-corner at center 300px, #f8004e, #5135b6) top left / 100vw;
	
	counter-reset: comment;
}

h1
{
	background: url(images/sun.svg) no-repeat bottom center;
	background-size: 30vw;
	
	margin-bottom: 0.25em;
	padding: 91px 25px 0 25px;
}
h1 img
{
	max-width: 26%;
}
h1 + h2
{
	padding-top: 100px;
}

figure > a > img, .post img:not(.icon), .post video
{
	display: flex; /* I have no idea _why_ this works, but it does :P */
	margin: 0.25em auto;
	border-radius: 5px;
	max-height: 85vh;
}

figure
{
	display: inline-block;
	
	margin: 10px;
}

figure > img
{
	position: relative;
	z-index: -10;
}

figure > a > img:first-child
{
	display: block;
	
	max-width: 250px;
}

figure.retired {
	filter: grayscale(50%) opacity(50%);
}


figcaption
{
	display: inline-block; /* For text based browsers */
	position: relative; top: -3.5em;
	padding-right: 0.6em;
	z-index: 10;
	
	background: rgba(137, 89, 217, 0.8);
	border-radius: 5px;
}


.research-item {
	margin: 0.5em 0;
	padding: 0.5em;
	
	background: rgba(137, 89, 217, .6);
	border-radius: 5px;
	
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto auto auto;
	grid-template-areas: "image title"
		"image description"
		"image actions";
}
.research-item .research-preview {
	grid-area: image;
	max-width: 10em;
	max-height: 10em;
	margin: 0 1em 0 0;
}
.research-item .research-title {
	grid-area: title;
}
.research-item .research-description {
	grid-area: description;
}
.research-item .research-action-links {
	grid-area: actions;
}

#tools ul.structural {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
	justify-items: center;
	grid-gap: 2em 0;
}

#tools figure {
	display: inline-grid;
	grid: 5em auto / auto;
	align-items: center;
	justify-items: center;
}

#tools .icon-large {
	min-width: 5em;
	max-width: 5em;
}
#tools figcaption {
	position: static;
	margin: 1em 0.5em 0 0.5em;
	padding-left: 0.6em;
}


footer
{
	margin-top: 20px;
	
	line-height: 1.5em;
}
body > footer	{ margin: 20px 10px; }

/************/

/*************************/
/* Toggable section code */
/*************************/
/*
.toggleable-section > input[type=checkbox]:first-child		{ display: none;	}
.toggleable-section > label									{ cursor: pointer;	}
.toggleable-section > input[type=checkbox]:checked ~ div	{ display: block; }
.toggleable-section > input[type=checkbox] ~ div
{
	display: none;
	transition: all 0.25s;
}
*/
/*************************/

/********************/
/* Reusable Classes */
/********************/
/* Compact */
/***********/
.small-icon		{ max-height: 24px; max-width: 24px; vertical-align: middle;	}
.icon			{ max-height: 26px; max-width: 26px; vertical-align: middle;	}
.large-icon		{ max-height: 48px; vertical-align: middle;	}
.x-large-icon	{ max-height: 96px; vertical-align: middle;	}

/************/
/* Extended */
/************/
.etalk, .notice
{
	margin	: 5px 8px;
	padding	: 5px 8px;
}

.help
{
	position: relative; top: 10px;
	
	border-bottom: 2px dotted white;
	
	font-weight: bold;
	cursor: help;
}
.panel, .post
{
	background: rgba(137, 89, 217, 0.6);
	border-radius: 5px;
}
.small-panel { background: rgba(185, 153, 238, 0.4); border-radius: 3px; }
.panel, .post
{
	padding: 15px 3rem 15px 3rem;
}
article img, article video	{ max-width: 100%;		}
article footer				{ line-height: 2.2em;	}
article footer .small-panel	{ white-space: nowrap;	}
.small-panel				{ padding: 5px 8px;		}
.panel h3, .post h2			{ margin-top: 5px;		}

.gist .blob-wrapper.data
{
	max-height: 600px;
	overflow: auto;
}

@media screen and (max-width: 1200px) {
	.navlist	{ display: none; }
}

.navlist
{
	margin: 5px;
	padding: 5px;
	
	z-index: 1000;
}
.navlist a
{
	display: block;
	
	margin: 5px;
	padding: 5px;
	
	font-weight: bolder;
	font-size: 1.2em;
}


/* we use etalk instead of email just in case spambots get smart O_o */
.etalk
{
	background		: rgba(255, 255, 255, 0.2);
	border-radius	: 5px;
}

/********************/

/************************/
/* Non-reusable Classes */
/************************/
.toggleable-section
{
	padding: 0  2vw 21em 2vw;
}
.toggleable-section h2
{
	margin-top: 0;
	padding: 0.9em 0.5em 1em 0.5em;
	
	font-size: 10vmin;
	
	text-shadow:
		rgba(120, 120, 120, 0.7) 5px 5px 5px,
		-1px -1px 0 #000,  
		1px -1px 0 #000,
		-1px 1px 0 #000,
		1px 1px 0 #000;
}
/****/
.blogtitle
{
	margin-top: -0.25em;
	margin-bottom: 0;
	
	padding: 0.25em 0.5em 2em 0.6em;
	
	background: url(images/bg-stardust-top-row.png) no-repeat 46.5% 0;
	background-size: 50%;
	
	font-size: 3em;
	text-shadow: rgba(120, 120, 120, 0.7) 5px 5px 5px;
}
#blog img
{
	max-height: 25vw;
}
/****/
.artbymythdael
{
	position: absolute; top: 10%; left: 60%;
	width: 30vmin;
	height: 8vmin;
	
	background-image: url(images/jammysplodger-min.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right center;
	
	color: transparent;
	
	/* animation: left2right 38s linear infinite; */
}
.artbymythdael:hover
{
	background-image: url(images/jammysplodger-exp.png)
}
/************************/

/*******/
/* IDs */
/*******/
#about h2 {
	margin-bottom: 0.5em;
	line-height: 1.5em;
	background: url(images/bg-row-1.png) no-repeat top center / contain;
}
#about {
	margin-top: -3.7em;
	background: url(images/bg-row-2.png) no-repeat bottom right / 85%;
}

#blog h2 {
	line-height: 2em;
	background: url(images/bg-row-3.png) no-repeat left center / contain;
}
#blog {
	background: url(images/bg-row-4.png) no-repeat bottom center / 91%;
}

#labs h2 {
	line-height: 2em;
	background: url(images/bg-row-5.png) no-repeat center center / contain;
}
#labs {
	background: url(images/bg-row-6.png) no-repeat bottom right / 85%;
}

#code h2 {
	line-height: 2.5em;
	background: url(images/bg-row-7.png) no-repeat left center / contain;
}
#code {
	background: url(images/bg-row-8.png) no-repeat bottom center / 85%;
	/*background-size: 85% !important;*/
}

#tools h2
{
	line-height: 2em;
	background: url(images/bg-row-9.png) no-repeat left center / contain;
}
#tools
{
	background: url(images/bg-row-10.png) no-repeat bottom center / 85%;
}
/*****************/

/**************/
/* Animations */
/**************/
@keyframes left2right {
	from	{ left: -35vmin; }
	to		{ left: 200vmin; }
}
/**************/

/****************/
/* Small Screens*/
/****************/
@media screen and (max-width: 768px) {
	h1 {
		margin-bottom: 0.75em;
	}
	.blogtitle {
		margin-top: -0.75em;
		background-size: 70%;
	}
	.toggleable-section h2 {
		margin: 5px;
	}
	.panel, .post {
		padding: 1em;
	}
}
@media screen and (max-width: 530px) {
	h1 {
		margin-bottom: 1em;
	}
	.blogtitle {
		margin-bottom: -1em;
		background-size: 95%;
	}
}

@media screen and (max-width: 480px) {
	figure > a > img:first-child {
		max-width: 80vw;
		height: 53vw;
	}
	.blogtitle { padding-top: 0; }
}
/****************/
